import React from "react";
import { Container, Typography } from "@mui/material";
import ContactUs from "../../components/contact-us";

const Financing = () => {
  return (
    <>
      <div className="wrapper">
        <div className="main-content finance-main-content">
          <section className="main-section">
            <Container maxWidth="xl">
              <div className="cs-content">
                <Typography variant="h1">Financing</Typography>
                <div className="finance-content">
                  <p>
                    Local Solar installers have access to provide third party
                    zero down financing that allows for monthly payments.
                  </p>
                  <p>
                    Zero down financing has really propelled the solar industry.
                    These options have allowed much more people to financially
                    afford solar. Very important to weigh getting your own money
                    or your own financing vs using a third party option. If you
                    using your own financing we suggest looking into security
                    backed loans first. Home equity line or loan. Maybe a loan
                    via a 401k if it is available as an option.
                  </p>
                  <p>
                    If using third party financing always important to really
                    know what the loan is that is being offered. Using a local
                    installer typically helps avoid a lot of the negatives of
                    being taking advantage of with a zero down loan option.
                    Loans can range from 5 years to 30 years.
                  </p>
                  <p>
                    Typically the 30 year loans will have the lowest monthly
                    payments. Often times less expensive than would be the
                    electric bill.
                  </p>
                </div>
              </div>

              <ContactUs />
            </Container>
          </section>
        </div>
      </div>
    </>
  );
};

export default Financing;
