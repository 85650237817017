import { MONTH_FIELDS } from "../constants/SolarCalc";

export const isNotNumber = (value) => {
  if (value) return !/^(([0-9]+(\.[0-9]+)?)|(\.[0-9]+))$/.test(value);
};

export const checkPercLimit = (value, key) => {
  if (key === "fieldConsumptionSelfProducedPower") {
    return value > 100;
  } else {
    return false;
  }
};

export const roundOffNumber = (number) => {
  if (number >= 1000) {
    const numZeros = Math.floor(Math.log10(number) - 3);
    const divisor = Math.pow(10, numZeros);
    const roundedNumber = Math.round(number / divisor) * divisor;

    return roundedNumber;
  } else {
    return number;
  }
};

export const calculateProductionIncludingDegradation = (value) => {
  let production = 0;
  let newVal = value;
  for (let i = 0; i < 25; i++) {
    newVal = i === 0 ? newVal * 0.98 : newVal * 0.995;
    production = production + newVal;
  }
  return roundOffNumber(Math.ceil(production));
};

export const calculateReturnWithUtilityRate = (netCost, saving) => {
  let year = 0;
  let cost = netCost;
  let savings = saving;
  let newVal = cost - savings;
  if (saving > 0) {
    for (let i = newVal; i > 0; i = cost - savings) {
      newVal = (cost - savings).toFixed(2);
      cost = newVal;
      savings = ((savings * 103) / 100).toFixed(2);
      year = year + 1;
    }
    let months = newVal / savings ?? 0;
    return year + months;
  } else return 0;
};

export const calculateTotalUsage = (prevState, field, value) => {
  const newFieldValues = MONTH_FIELDS.map((key) => {
    if (key === field) {
      return parseFloat(value);
    }
    return parseFloat(prevState[key].value);
  });

  const totalUsage = newFieldValues
    .filter(Boolean)
    .reduce((sum, usage) => sum + Number(usage), 0);
  return parseFloat(totalUsage);
};
