export const _CONSUMPTION_PRICE_PER_KWH = "0.15"
export const _CONSUMPTION_PRICE_GRID_PER_KWH = "0.00"
export const _CONSUMPTION_PRICE_BASE_FEE = "0.00"
export const _CONSUMPTION_PRICE_DG_FEE = "0.00"
export const _CONSUMPTION_PRODUCTION_USED_COST = "0.00"
export const _CONSUMPTION_UTILITY_CREDIT = { id: "Directly", label: "Directly" }
export const _CONSUMPTION_UTILITY_CREDIT_LIST = [
    {
        id: "Directly",
        label: "Directly"
    },
    {
        id: "Monthly",
        label: "Monthly"
    },
    {
        id: "Annual",
        label: "Annual"
    },
    {
        id: "Indefinite",
        label: "Indefinite"
    }
]
export const _CONSUMPTION_HANDLE_CHANGED_PRICE_PER_KWH = "PRICE_PER_KWH"
export const _CONSUMPTION_HANDLE_CHANGED_PRICE_GRID_PER_KWH = "PRICE_GRID_PER_KWH"
export const _CONSUMPTION_HANDLE_CHANGED_PRICE_BASE_FEE = "PRICE_BASE_FEE"
export const _CONSUMPTION_HANDLE_CHANGED_PRICE_DG_FEE = "PRICE_DG_FEE"
export const _CONSUMPTION_HANDLE_CHANGED_PRODUCTION_USED_COST = "PRODUCTION_USED_COST"
export const _CONSUMPTION_HANDLE_CHANGED_UTILITY_CREDIT = "UTILITY_CREDIT"
export const _CONSUMPTION_HANDLE_CHANGED_ANNUAL = "ANNUAL"
export const _CONSUMPTION_HANDLE_CHANGED_AVERAGE_MONTHLY = "AVERAGE MONTHLY"
export const _CONSUMPTION_HANDLE_CHANGED_JANUARY = "JANUARY"
export const _CONSUMPTION_HANDLE_CHANGED_FEBRUARY = "FEBRUARY"
export const _CONSUMPTION_HANDLE_CHANGED_MARCH = "MARCH"
export const _CONSUMPTION_HANDLE_CHANGED_APRIL = "APRIL"
export const _CONSUMPTION_HANDLE_CHANGED_MAY = "MAY"
export const _CONSUMPTION_HANDLE_CHANGED_JUNE = "JUNE"
export const _CONSUMPTION_HANDLE_CHANGED_JULY = "JULY"
export const _CONSUMPTION_HANDLE_CHANGED_AUGUST = "AUGUST"
export const _CONSUMPTION_HANDLE_CHANGED_SEPTEMBER = "SEPTEMBER"
export const _CONSUMPTION_HANDLE_CHANGED_OCTOBER = "OCTOBER"
export const _CONSUMPTION_HANDLE_CHANGED_NOVEMBER = "NOVEMBER"
export const _CONSUMPTION_HANDLE_CHANGED_DECEMBER = "DECEMBER"
export const MONTH_FIELDS = ["fieldConsumptionJanuaryUsage", "fieldConsumptionFebruaryUsage", "fieldConsumptionMarchUsage", "fieldConsumptionAprilUsage", "fieldConsumptionMayUsage", "fieldConsumptionJuneUsage", "fieldConsumptionJulyUsage", "fieldConsumptionAugustUsage", "fieldConsumptionSeptemberUsage", "fieldConsumptionOctoberUsage", "fieldConsumptionNovemberUsage", "fieldConsumptionDecemberUsage",]
export const FIELDCONSUMPTIONPRICEPERKWH = {
    value: "15",
    error: null,
    help: [<span>Value provided in dollars</span>]
}
export const FIELDCONSUMPTIONPRICEGRIDPERKWH = {
    value: "15",
    error: null,
    help: [<span>Value provided in dollars</span>]
}
export const FIELDCONSUMPTIONPRICEBASEFEE = {
    value: "10",
    error: null,
    help: [<span>Value provided in dollars</span>]
}
export const FIELDCONSUMPTIONPRICEDGFEE = {
    value: "",
    error: null,
    help: [<span>Value provided in dollars</span>]
}
export const FIELDCONSUMPTIONSELFPRODUCEDPOWER = {
    value: "50",
    error: null,
    help: [<span>Value provided in %</span>]
}
export const FIELDCONSUMPTIONINCREASEINKWH = {
    value: "3",
    error: null,
    help: [<span>Value provided in %</span>]
}
export const FIELDCONSUMPTIONADDINCENTIVE = {
    value: "0",
    error: null,
    help: [<span>Value provided in %</span>]
}
export const FIELDCONSUMPTIONMONTHLYBILL = {
    value: "",
    error: null,
    help: [<span>Value provided in kilowatts/hour</span>]
}
export const FIELDCONSUMPTIONANNUALUSAGE = {
    value: "12000",
    error: null,
    help: [<span>Value provided in kilowatts/hour</span>]
}
export const FIELDCONSUMPTIONJANUARYUSAGE = {
    value: "1000",
    error: null,
    help: [<span>Value provided in kilowatts/hour</span>]
}
export const FIELDCONSUMPTIONFEBRUARYUSAGE = {
    value: "1000",
    error: null,
    help: [<span>Value provided in kilowatts/hour</span>]
}
export const FIELDCONSUMPTIONMARCHUSAGE = {
    value: "1000",
    error: null,
    help: [<span>Value provided in kilowatts/hour</span>]
}
export const FIELDCONSUMPTIONAPRILUSAGE = {
    value: "1000",
    error: null,
    help: [<span>Value provided in kilowatts/hour</span>]
}
export const FIELDCONSUMPTIONMAYUSAGE = {
    value: "1000",
    error: null,
    help: [<span>Value provided in kilowatts/hour</span>]
}
export const FIELDCONSUMPTIONJUNEUSAGE = {
    value: "1000",
    error: null,
    help: [<span>Value provided in kilowatts/hour</span>]
}
export const FIELDCONSUMPTIONJULYUSAGE = {
    value: "1000",
    error: null,
    help: [<span>Value provided in kilowatts/hour</span>]
}
export const FIELDCONSUMPTIONAUGUSTUSAGE = {
    value: "1000",
    error: null,
    help: [<span>Value provided in kilowatts/hour</span>]
}
export const FIELDCONSUMPTIONSEPTEMBERUSAGE = {
    value: "1000",
    error: null,
    help: [<span>Value provided in kilowatts/hour</span>]
}
export const FIELDCONSUMPTIONOCTOBERUSAGE = {
    value: "1000",
    error: null,
    help: [<span>Value provided in kilowatts/hour</span>]
}
export const FIELDCONSUMPTIONNOVEMBERUSAGE = {
    value: "1000",
    error: null,
    help: [<span>Value provided in kilowatts/hour</span>]
}
export const FIELDCONSUMPTIONDECEMBERUSAGE = {
    value: "1000",
    error: null,
    help: [<span>Value provided in kilowatts/hour</span>]
}
export const DEFAULT = {
    value: "",
    error: null,
    help: [<span>Value provided in kilowatts/hour</span>]
}


export const EXAMPLE2 = {
    FIELDCONSUMPTIONPRICEPERKWH: {
        value: "13",
        error: null,
        help: [<span>Value provided in dollars</span>]
    },
    FIELDCONSUMPTIONPRICEGRIDPERKWH: {
        value: "13",
        error: null,
        help: [<span>Value provided in dollars</span>]
    },
    FIELDCONSUMPTIONPRICEBASEFEE: {
        value: "8",
        error: null,
        help: [<span>Value provided in dollars</span>]
    },
    FIELDCONSUMPTIONPRICEDGFEE: {
        value: "",
        error: null,
        help: [<span>Value provided in dollars</span>]
    },
    FIELDCONSUMPTIONSELFPRODUCEDPOWER: {
        value: "40",
        error: null,
        help: [<span>Value provided in %</span>]
    },
    FIELDCONSUMPTIONINCREASEINKWH: {
        value: "5",
        error: null,
        help: [<span>Value provided in %</span>]
    },
    FIELDCONSUMPTIONADDINCENTIVE: {
        value: "1000",
        error: null,
        help: [<span>Value provided in %</span>]
    },
    FIELDCONSUMPTIONANNUALUSAGE: {
        value: "12100",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONJANUARYUSAGE: {
        value: "600",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONFEBRUARYUSAGE: {
        value: "600",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONMARCHUSAGE: {
        value: "500",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONAPRILUSAGE: {
        value: "600",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONMAYUSAGE: {
        value: "1000",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONJUNEUSAGE: {
        value: "1500",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONJULYUSAGE: {
        value: "1800",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONAUGUSTUSAGE: {
        value: "1800",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONSEPTEMBERUSAGE: {
        value: "1300",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONOCTOBERUSAGE: {
        value: "1000",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONNOVEMBERUSAGE: {
        value: "800",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONDECEMBERUSAGE: {
        value: "600",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    }
}

export const EXAMPLE3 = {
    FIELDCONSUMPTIONPRICEPERKWH: {
        value: "15",
        error: null,
        help: [<span>Value provided in dollars</span>]
    },
    FIELDCONSUMPTIONPRICEGRIDPERKWH: {
        value: "10",
        error: null,
        help: [<span>Value provided in dollars</span>]
    },
    FIELDCONSUMPTIONPRICEBASEFEE: {
        value: "9.95",
        error: null,
        help: [<span>Value provided in dollars</span>]
    },
    FIELDCONSUMPTIONPRICEDGFEE: {
        value: "",
        error: null,
        help: [<span>Value provided in dollars</span>]
    },
    FIELDCONSUMPTIONSELFPRODUCEDPOWER: {
        value: "60",
        error: null,
        help: [<span>Value provided in %</span>]
    },
    FIELDCONSUMPTIONINCREASEINKWH: {
        value: "5",
        error: null,
        help: [<span>Value provided in %</span>]
    },
    FIELDCONSUMPTIONADDINCENTIVE: {
        value: "0",
        error: null,
        help: [<span>Value provided in %</span>]
    },
    FIELDCONSUMPTIONANNUALUSAGE: {
        value: "20100",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONJANUARYUSAGE: {
        value: "2000",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONFEBRUARYUSAGE: {
        value: "2500",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONMARCHUSAGE: {
        value: "2000",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONAPRILUSAGE: {
        value: "800",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONMAYUSAGE: {
        value: "1000",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONJUNEUSAGE: {
        value: "1500",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONJULYUSAGE: {
        value: "1800",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONAUGUSTUSAGE: {
        value: "1800",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONSEPTEMBERUSAGE: {
        value: "1300",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONOCTOBERUSAGE: {
        value: "1200",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONNOVEMBERUSAGE: {
        value: "2000",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    },
    FIELDCONSUMPTIONDECEMBERUSAGE: {
        value: "2200",
        error: null,
        help: [<span>Value provided in kilowatts/hour</span>]
    }
}