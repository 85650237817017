import React from "react";
import { ThemeProvider } from "@mui/material";
import { Theme } from "./theme";
import { RouteList } from "./routes";


function App() {
  return ( 
    <>
      <ThemeProvider theme={Theme}>
        <RouteList />
      </ThemeProvider>
    </>
  );
}

export default App;
