import React from 'react';
import { Container, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { infoWhite } from "../../../assets/images";

export default function SolarNumber({ solarCalculationData }) {
  return (
    <>
      <section className="solar-info-section">
        <Container maxWidth="xl">
          <div className="solar-info-box">
            <Typography variant="h4">Potential Solar Numbers</Typography>
            <div className="solar-info-outer">
              <Grid container spacing={3}>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h3">{`${solarCalculationData.totalPanels || 0} Panels`}</Typography>
                  <p className="solar-message">
                    <Tooltip
                      title="Estimated amount of solar panels you will need to reproduce the kWh you consume based on ideal circumstances."
                      arrow
                      placement="top"
                      enterTouchDelay={0}
                    >
                      <IconButton>
                        <img src={infoWhite} alt="info" />
                      </IconButton>
                    </Tooltip>
                    Total amount of solar panels
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h3">{`$${solarCalculationData.totalSavingFirstYear || 0}`}</Typography>
                  <p className="solar-message">
                    <Tooltip
                      title="Your total savings first year based on numbers you provided."
                      arrow
                      placement="top"
                      enterTouchDelay={0}
                    >
                      <IconButton>
                        <img src={infoWhite} alt="info" />
                      </IconButton>
                    </Tooltip>
                    Total estimated savings first year
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h3">{`$${solarCalculationData.netSolarCost || 0}`}</Typography>
                  <p className="solar-message">
                    <Tooltip
                      title={`Cost of solar after incentives and tax credits.`}
                      arrow
                      placement="top"
                      enterTouchDelay={0}
                    >
                      <IconButton>
                        <img src={infoWhite} alt="info" />
                      </IconButton>
                    </Tooltip>
                    Total net cost of solar
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h3">{`${solarCalculationData.returnOnSaving || 0}%`}</Typography>
                  <p className="solar-message">
                    <Tooltip
                      title="This number is based on the sum coming up and the installer doing their job correctly."
                      arrow
                      placement="top"
                      enterTouchDelay={0}
                    >
                      <IconButton>
                        <img src={infoWhite} alt="info" />
                      </IconButton>
                    </Tooltip>
                    Return on savings for first year
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h3">{`${solarCalculationData.returnOnSavingYears || 0} years`}</Typography>
                  <p className="solar-message">
                    <Tooltip
                      title="How many years it will take you to have a complete return on your money - tax free- based off your savings."
                      arrow
                      placement="top"
                      enterTouchDelay={0}
                    >
                      <IconButton>
                        <img src={infoWhite} alt="info" />
                      </IconButton>
                    </Tooltip>
                    Complete return on savings
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h3">{`${solarCalculationData.returnOnSavingYearsUtility || 0} years`}</Typography>
                  <p className="solar-message">
                    <Tooltip
                      title="Complete return on money through savings based on the utility rates going up."
                      arrow
                      placement="top"
                      enterTouchDelay={0}
                    >
                      <IconButton>
                        <img src={infoWhite} alt="info" />
                      </IconButton>
                    </Tooltip>
                    Complete return on savings with utility rate increases
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h3">{`${solarCalculationData.totalProduction25Years || 0} kWh`}</Typography>
                  <p className="solar-message">
                    <Tooltip
                      title="Amount of kWh produced if no degradation over 25 years."
                      arrow
                      placement="top"
                      enterTouchDelay={0}
                    >
                      <IconButton>
                        <img src={infoWhite} alt="info" />
                      </IconButton>
                    </Tooltip>
                    Total production of solar over 25 years
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h3">{`$${solarCalculationData.costToProducePower25Years || 0}/kWh`}</Typography>
                  <p className="solar-message">
                    <Tooltip
                      title="The cost of your system spread out over the kWh produced for 25 years."
                      arrow
                      placement="top"
                      enterTouchDelay={0}
                    >
                      <IconButton>
                        <img src={infoWhite} alt="info" />
                      </IconButton>
                    </Tooltip>
                    Cost per kWh to produce power over 25 years
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h3">{`${solarCalculationData.totalProductionIncludeDegradation || 0} kWh`}</Typography>
                  <p className="solar-message">
                    <Tooltip
                      title="Amount of kWh produced with the panels going down in efficiency over the 25 year period."
                      arrow
                      placement="top"
                      enterTouchDelay={0}
                    >
                      <IconButton>
                        <img src={infoWhite} alt="info" />
                      </IconButton>
                    </Tooltip>
                    Total Production including degradation on panels
                  </p>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Typography variant="h3">{`$${solarCalculationData.estimatedPriceToProducePower25years || 0}/kWh`}</Typography>
                  <p className="solar-message">
                    <Tooltip
                      title="The cost of your system spread out over the kWh produced for 25 years."
                      arrow
                      placement="top"
                      enterTouchDelay={0}
                    >
                      <IconButton>
                        <img src={infoWhite} alt="info" />
                      </IconButton>
                    </Tooltip>
                    Estimated price to produce power over 25 years with
                    degradation
                  </p>
                </Grid>
              </Grid>
            </div>
          </div>
        </Container>
      </section>
    </>
  )
}
