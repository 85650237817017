export const mapEvent = {
    CLICK: 'click',
    GRAD_END: 'dragend',
    ZOOM_CHANGED: 'zoom_changed',
    DOM_READY: 'domready',
    INSERT_AT: 'insert_at',
    SET_AT: 'set_at',
    DOUBLE_CLICK: 'dblclick',
    GRAG: 'drag',
    DRAG_START: 'dragstart',
    DRAWING_CHANGED: 'drawingmode_changed',
    MOUSE_MOVE: 'mousemove',
    MOUSE_OVER: 'mouseover',
    MOUSE_OUT: 'mouseout',
    POLYGON_COMPLETE: 'polygoncomplete',
    MAP_CHANGED: 'maptypeid_changed',
    KEY_UP: 'keyup'
}

export const getMapAimation = (key) => {
    let list = {
        DROP: window.google.maps.Animation.DROP,
        BOUNCE: window.google.maps.Animation.BOUNCE,
    }
    return list[key]
}

export const getMapControlPosition = (key) => {
    let list = {
        TOP_CENTER: window.google.maps.ControlPosition.TOP_CENTER,
        TOP_LEFT: window.google.maps.ControlPosition.TOP_LEFT,
        TOP_RIGHT: window.google.maps.ControlPosition.TOP_RIGHT,
        BOTTOM_CENTER: window.google.maps.ControlPosition.BOTTOM_CENTER,
        BOTTOM_LEFT: window.google.maps.ControlPosition.BOTTOM_LEFT,
        BOTTOM_RIGHT: window.google.maps.ControlPosition.BOTTOM_RIGHT,
        LEFT_BOTTOM: window.google.maps.ControlPosition.LEFT_BOTTOM,
        LEFT_CENTER: window.google.maps.ControlPosition.LEFT_CENTER,
        LEFT_TOP: window.google.maps.ControlPosition.LEFT_TOP,
        RIGHT_CENTER: window.google.maps.ControlPosition.RIGHT_CENTER,
        RIGHT_TOP: window.google.maps.ControlPosition.RIGHT_TOP,
        RIGHT_BOTTOM: window.google.maps.ControlPosition.RIGHT_BOTTOM
    }
    return list[key]
}

export const getMapControlStyle = (key) => {
    let list = {
        HORIZONTAL_BAR: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
        DROPDOWN_MENU: window.google.maps.MapTypeControlStyle.DROPDOWN_MENU,
        DEFAULT: window.google.maps.MapTypeControlStyle.DEFAULT,
    }
    return list[key]
}

export const getMapTypeId = (key) => {
    let list = {
        list: ["roadmap", "terrain", "satellite", "hybrid"],
        HYBRID: window.google.maps.MapTypeId.HYBRID,
        ROADMAP: window.google.maps.MapTypeId.ROADMAP
    }
    return list[key]
}

export const loadMap = (mapRef, mapOptions) => {
    return new window.google.maps.Map(
        mapRef,
        {
            ...mapOptions
        }
    )
}

export const getMarker = (markerOptions) => {
    return new window.google.maps.Marker(
        {
            ...markerOptions
        }
    )
}

export const getInfoWindow = (infoOptions) => {
    return new window.google.maps.InfoWindow(infoOptions)
}

export const addEventListener = (marker, event, cb) => {
    return window.google.maps.event.addListener(
        marker,
        event,
        (evt) => {
            cb(evt)
        }
    )
}

export const autocompleteService = (address, cb) => {
    return new window.google.maps.places.AutocompleteService().getPlacePredictions(
        { input: address },
        async (data) => {
            cb(data)
        }
    );
}

export const getGeocoder = (placeId, cb) => {
    return new window.google.maps.Geocoder()
        .geocode({ placeId: placeId })
        .then(async ({ results }) => {
            cb(results)
        });
}

export const reverseGeoCoder = (lat, lng, cb) => {
    var location = new window.google.maps.LatLng(lat, lng);

    return new window.google.maps.Geocoder()
        .geocode({ location: location})
        .then(async ({ results}) => {
            cb(results)
        })
}

export const getPostCode = (place)  => {
    for (var i = 0; i < place.address_components.length; i++) {
      for (var j = 0; j < place.address_components[i].types.length; j++) {
        if (place.address_components[i].types[j] === "postal_code") {
          return place.address_components[i].long_name;
        }
      }
    }
}