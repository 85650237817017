import { Button, Container, Typography } from '@mui/material'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

export default function AddBattery() {
  const location = useLocation()
  const isBatterySection = location.pathname.includes('battery-specs') ? true : false
  
  return (
    <>
      <section className="add-battery-section">
        <Container maxWidth="xl">
          <Typography variant="h4">Want to add a battery?</Typography>
          <div className="battery-box">
            <div className="battery-info">
              <div className="bi-inner">
                <Typography variant="h2" className="h2">
                  $ 13k
                </Typography>
                <span>13.5 kWh</span>
              </div>
              <div className="bi-inner">
                <Typography variant="h2" className="h2">
                  $ 7k
                </Typography>
                <span>27 kWh</span>
              </div>
              <div className="bi-inner">
                <Typography variant="h2" className="h2">
                  $ 7k
                </Typography>
                <span>40.5 kWh</span>
              </div>
              <div className="bi-inner">
                <Typography variant="h2" className="h2">
                  $ 7k
                </Typography>
                <span>54 kWh</span>
              </div>
              <div className="bi-inner">
                <Typography variant="h2" className="h2">
                  $ 7k
                </Typography>
                <span>67.5 kWh</span>
              </div>
              <div className="bi-inner">
                <Typography variant="h2" className="h2">
                  $ 7k
                </Typography>
                <span>81 kWh</span>
              </div>
            </div>
            <div className="text-center battery-note">
              <p>First battery includes inverter and other materials. Additional battery does not include inverter. It connects to existing battery</p>
            </div>
            {!isBatterySection ? (
              <div className="text-center" style={{paddingTop: '16px'}}>
                <Link to="/battery-specs" className='w-100'>
                  <Button variant="contained" color="primary">
                    Battery Specs
                  </Button>
                </Link>
              </div>
            ) 
            : (null)}
          </div>
        </Container>
      </section>
    </>
  )
}
