import { Box, Typography } from "@mui/material";
import React from "react";

export const HelperTextError = React.memo(
  ({ messageList }) => {
    try {
      return messageList.map(
        (messageListMap) => (
          messageListMap.map(
            (messageMap, index) => (
              <Box key={index} component={"span"} sx={{ display: "flex" }}>
                <Typography component={"span"} variant={"caption"} color={"error"}>- {messageMap}</Typography>
              </Box>
            )
          )
        )
      )
    } catch (e) {
      return null
    }
  }
)

export const HelperTextHelp = React.memo(
  ({ messageList }) => {
    try {
      return messageList.map(
        (messageMap, index) => (
          <Box key={index} component={"span"} sx={{ display: "flex" }}>
            <Typography component={"span"} variant={"caption"} color={"inherit"}>{messageMap}</Typography>
          </Box>
        )
      )
    } catch (e) {
      return null
    }
  }
)
