import { Button, Container, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

export default function ContactUs() {
  return (
    <section className="contact-us-section">
      <Container maxWidth="xl">
        <div className="contact-block">
          <div className="contact-bg-left"></div>
          <div className="contact-bg-right"></div>
          <Typography variant="h4">Contact us</Typography>
          <p>
            We are happy to speak with you and will respect your personal information. We are happy to dive deeper into your solar project.   
          </p>
          <div className="button-block">
            <Link to="/contact-us" className='w-100'>
              <Button variant="contained" color="primary">
                Contact Us
              </Button>
            </Link>
          </div>
        </div>
      </Container>
    </section>
  )
}
