import { Box as MuiBox } from "@mui/material"
import React from "react"
import * as AppUtilMap from "../../utils/maps/googleMap"

export const AutocompleteGoogleAddressMap = React.memo(
    ({ field = { value: {} }, handleChanged = null, className = "" }) => {
        const [
            {
                mapContainerRefHeight,
            },
            setState
        ] = React.useState(
            {
                mapContainerRefHeight: 0
            }
        )
        const isComponentMountedRef = React.useRef(true)
        const mapContainerRef = React.useRef(null)
        const mapContentRef = React.useRef(null)

        const fetchMap = React.useCallback(
            async () => {
                try {
                    let iteration = 0
                    while (iteration < 10) {
                        if (typeof window !== "undefined" && window.document && window.google) {
                            if (mapContainerRef.current && mapContentRef.current) {
                                if (field && field.value && field.value.id && field.value.lat && field.value.lng && field.value.zoom && field.value.label) {
                                    try {
                                        const mapContainerRefWidth = mapContainerRef.current.getBoundingClientRect().width
                                        if (mapContainerRefWidth && typeof mapContainerRefWidth === "number" && mapContainerRefWidth !== mapContainerRefHeight) {
                                            if (isComponentMountedRef.current === true) {
                                                setState(
                                                    (oldState) => (
                                                        {
                                                            ...oldState,
                                                            mapContainerRefHeight: mapContainerRefWidth
                                                        }
                                                    )
                                                )
                                                return null
                                            }
                                        }
                                    } catch (e) {
                                    }
                                    const fieldValueLatLng = {
                                        "lat": field.value.lat,
                                        "lng": field.value.lng
                                    }
                                    const fieldValueZoom = field.value.zoom
                                    const mapOptions = {
                                        center: fieldValueLatLng,
                                        zoom: fieldValueZoom,
                                        heading: 0,
                                        tilt: 0,
                                        gestureHandling: "cooperative",
                                        scrollwheel: false,
                                        controlSize: 25,

                                        mapTypeControl: false,
                                        mapTypeControlOptions: {
                                            position: AppUtilMap.getMapControlPosition('TOP_LEFT'), 
                                            style: AppUtilMap.getMapControlStyle('HORIZONTAL_BAR'), 
                                            mapTypeIds: ["satellite", "hybrid"]
                                        },
                                        mapTypeId: AppUtilMap.getMapTypeId('HYBRID'), 

                                        fullscreenControl: true,
                                        fullscreenControlOptions: {
                                            position: AppUtilMap.getMapControlPosition('RIGHT_TOP'),
                                        },

                                        zoomControl: true,
                                        zoomControlOptions: {
                                            position: AppUtilMap.getMapControlPosition('RIGHT_TOP'),
                                        },

                                        rotateControl: false,
                                        rotateControlOptions: {
                                            position: AppUtilMap.getMapControlPosition('RIGHT_BOTTOM')
                                        },

                                        streetViewControl: true,
                                        streetViewControlOptions: {
                                            position: AppUtilMap.getMapControlPosition('RIGHT_BOTTOM')
                                        },

                                        keyboardShortcuts: false,
                                        scaleControl: false
                                    }
                                    const map = await AppUtilMap.loadMap(mapContentRef.current, mapOptions)
                                    let mapMarkerOption = {
                                        map: map,
                                        position: fieldValueLatLng,
                                        animation: AppUtilMap.getMapAimation('DROP'),
                                        title: field.value.label,
                                        draggable: true
                                    }
                                    const fieldValueMarker = await AppUtilMap.getMarker(mapMarkerOption)
                                    const fieldValueInfoWindow = await AppUtilMap.getInfoWindow()
                                   
                                    const fieldValueInfoWindowName = field.value.label ? `<div style="margin: 0; padding: 4px 0"><strong>${field.value.label}</strong></div>` : ""
                                    fieldValueInfoWindow.setContent(`${fieldValueInfoWindowName}`)
                                    AppUtilMap.addEventListener(fieldValueMarker,
                                        AppUtilMap.mapEvent.CLICK,
                                        async () => {
                                            try {
                                                if (fieldValueInfoWindow.getMap()) {
                                                    fieldValueInfoWindow.close()
                                                    fieldValueMarker.setAnimation(AppUtilMap.getMapAimation('BOUNCE'))
                                                } else {
                                                    fieldValueInfoWindow.open(map, fieldValueMarker)
                                                    fieldValueMarker.setAnimation(null)
                                                }
                                            } catch (e) {
                                            }
                                        }
                                    )
                                    AppUtilMap.addEventListener(fieldValueMarker,
                                        AppUtilMap.mapEvent.GRAD_END,
                                        async (markerUpdated) => {
                                            try {
                                                const markerUpdatedLatLng = markerUpdated.latLng
                                                const latUpdated = markerUpdatedLatLng.lat()
                                                const lngUpdated = markerUpdatedLatLng.lng()
                                                const zoom = map.getZoom()
                                                await handleChanged(
                                                    {
                                                        ...field.value,
                                                        lat: latUpdated,
                                                        lng: lngUpdated,
                                                        zoom: zoom
                                                    }
                                                )
                                                    .then()
                                                    .catch()
                                                    .finally()
                                            } catch (e) {
                                            }
                                        }
                                    )
                                    AppUtilMap.addEventListener(map,
                                        AppUtilMap.mapEvent.ZOOM_CHANGED,
                                        async () => {
                                            try {
                                                const markerLatLng = fieldValueMarker.position
                                                const lat = markerLatLng.lat()
                                                const lng = markerLatLng.lng()
                                                const zoomUpdated = map.getZoom()
                                                await handleChanged(
                                                    {
                                                        ...field.value,
                                                        lat: lat,
                                                        lng: lng,
                                                        zoom: zoomUpdated
                                                    }
                                                )
                                                    .then()
                                                    .catch()
                                                    .finally()
                                            } catch (e) {
                                            }
                                        })
                                   
                                } else {
                                    if (isComponentMountedRef.current === true) {
                                        setState(
                                            (oldState) => (
                                                {
                                                    ...oldState,
                                                    mapContainerRefHeight: 0
                                                }
                                            )
                                        )
                                        return null
                                    }
                                }
                                break
                            }
                        }
                        iteration += 1
                    }
                } catch (e) {
                }
            },
            [
                mapContainerRefHeight,
                field,
                handleChanged
            ]
        )

        React.useEffect(
            () => {
                isComponentMountedRef.current = true

                fetchMap().then().catch().finally()

                return () => {
                    isComponentMountedRef.current = false
                }
            },
            [
                fetchMap
            ]
        )

        return (
            <MuiBox
                ref={mapContainerRef}
                component={"div"}
                className={className}
                sx={
                    {
                        // height: mapContainerRefHeight && mapContainerRefHeight !== 0 ? `${mapContainerRefHeight}px` : 0,
                        height: mapContainerRefHeight && mapContainerRefHeight !== 0 ? '300px' : 0,
                        "& .gm-style > div > div > a[href^='https://maps.google.com/maps']": {
                            // display: "none !important",
                            // background: "none !important"
                        },
                        "& .gm-style > div:last-child": {
                            display: "none !important",
                            background: "none !important"
                        }
                    }
                }
            >
                <MuiBox
                    ref={mapContentRef}
                    component={"div"}
                    sx={
                        {
                            width: "100%",
                            height: "100%",
                            margin: 0,
                            padding: 0,
                            backgroundColor: theme => theme.palette.grey[100]
                        }
                    }
                />
            </MuiBox>
        )
    }
)
