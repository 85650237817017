import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { AppBar, Container, Typography, Button } from "@mui/material";
import { newLogoHeader, close } from "../../assets/images";

const Header = () => {
  const location = useLocation();
  // Menu JS
  function MobileClick() {
    document.body.classList.toggle("menu-open");
  }

  function MenuClick() {
    document.body.classList.remove("menu-open");
    window.scrollTo(0, 0)
  }

  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.pageYOffset);
    // clean up code
    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  if (offset > 80) {
    document.body.classList.add("page-scrolled");
  } else {
    document.body.classList.remove("page-scrolled");
  }

  return (
    <>
      <AppBar>
        <div className="overlay-content">
          <Link className="close" onClick={MenuClick}>
            <img src={close} alt="close" />
          </Link>
          <div className="menu-lg-font">
            <Typography variant="h1">Menu</Typography>
          </div>
          <div className="menu-content">
            <ul className="overlay-menu">
              <li>
                <Link to="/" title="Home" onClick={MenuClick} className={location.pathname === "/" ? 'active' : ''}>
                  Home
                </Link>
              </li>
              <li>
                <Link to="/about-us" title="About us" onClick={MenuClick} className={location.pathname === "/about-us" ? 'active' : ''}>
                  About us
                </Link>
              </li>
              {/* <li>
                <Link to="/materials" title="Materials" onClick={MenuClick} className={location.pathname === "/materials" ? 'active' : ''}>
                  Materials
                </Link>
              </li> */}
              <li>
              <Link
                  to="/battery-specs"
                  title="Battery Specs"
                  onClick={MenuClick}
                  className={location.pathname === "/battery-specs" ? 'active' : ''}
                >
                  Battery Specs
                </Link>
                {/* <Link
                  to="/battery-calculate"
                  title="Battery calculator"
                  onClick={MenuClick}
                  className={location.pathname === "/battery-calculate" ? 'active' : ''}
                >
                  Battery calculator
                </Link> */}
              </li>
              <li>
                <Link to="/financing" title="Financing" onClick={MenuClick} className={location.pathname === "/financing" ? 'active' : ''}>
                  Financing
                </Link>
              </li>
              <li>
                <Link to="/contact-us" title="Contact Us" onClick={MenuClick} className={location.pathname === "/contact-us" ? 'active' : ''}>
                  Contact Us
                </Link>
              </li>
              <li>
                <Link to="/faq" title="FAQ" onClick={MenuClick} className={location.pathname === "/faq" ? 'active' : ''}>
                  FAQ
                </Link>
              </li>
            </ul>
            <div className="login-block">
              <Typography variant="h3" className="h3">
                Local Solar Installation Partners
              </Typography>
              <Button href="https://app.localpower.io" variant="contained" color="primary">
                Login
              </Button>
            </div>
          </div>
        </div>
        <Container maxWidth="xl">
          <Link className="logo" title="LocalPower.io" to="/">
            <img src={newLogoHeader} alt="LocalPower.io" />
          </Link>
          <div className="menu-icon" onClick={MobileClick}>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </Container>
      </AppBar>
    </>
  );
};

export default Header;
