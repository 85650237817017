import * as React from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
} from "@mui/material";
import ContactUs from "../../components/contact-us";
import SolarNumber from "./solarNumber";
import AddBattery from "./addBattery";
import AddCost from "./addCost";
import { SolarCalculator } from "./solarCalculator";
import { PricingDetails } from "./pricingDetails";

const Home = React.memo(() => {
  const [solarCalculationData, setSolarCalculationData] = React.useState({
    totalPanels: '',
    totalSavingFirstYear: '',
    netSolarCost: '',
    returnOnSaving: '',
    returnOnSavingYears: '',
    returnOnSavingYearsUtility: '',
    totalProduction25Years: '',
    costToProducePower25Years: '',
    totalProductionIncludeDegradation: '',
    estimatedPriceToProducePower25years: ''
  });

  const [showCalculator, setShowCalculator] = React.useState(false);

  const solarNumberRef = React.useRef(null)

  return (
    <>
      <div className="wrapper">
        <div className="main-content">

          <SolarCalculator 
            showCalculator={showCalculator}
            setShowCalculator={setShowCalculator}
            setSolarCalculationData={setSolarCalculationData}
            solarNumberRef={solarNumberRef}
          />

          {showCalculator? (
            <div ref={solarNumberRef}>
              <SolarNumber solarCalculationData={solarCalculationData} />
            </div>
          ) : (null)}

          <PricingDetails />

          <AddBattery />

          <AddCost />

          <ContactUs />

          {/* Finance Block */}
          <section className="finance-section">
            <Container maxWidth="xl">
              <Typography variant="h3" className="h3">
                Financing
              </Typography>
              <p className="finance-content">
                Local Solar installers have access to provide third party zero
                down financing that allows for monthly payments.
              </p>
              <Link to="/financing" className='w-100'>
                <Button variant="contained" color="primary">
                  read more
                </Button>
              </Link>
            </Container>
          </section>
        </div>
      </div>
    </>
  );
});

export default Home;
