import React from "react";
import { Container, Typography } from "@mui/material";
import ContactUs from "../../components/contact-us";

const About = () => {
  return (
    <>
      <div className="wrapper">
        <div className="main-content about-main-content">
          <section className="main-section">
            <Container maxWidth="xl">
              <div className="cs-content">
                <Typography variant="h1">About Localpower.io</Typography>
                <div className="about-content">
                  <p>
                    Local Power is built to provide transparency. Too many Texans have been taken advantage of by predatory solar practices. We provide a very fair bid that allows us to provide everything and maintain a fair margin. We want you to trust but verify. At Local power we want to help people go solar with no pressure and factual information.  
                  </p>
                </div>
              </div>
            </Container>
          </section>
          <ContactUs />
        </div>
      </div>
    </>
  );
};

export default About;
