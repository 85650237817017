import * as React from "react";
import { Card, CardContent, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { arrowIcon, checkIcon } from "../../../assets/images";

const listItems = [
    'Full Service Installation',
    'Cash or Finance Available',
    '25 Years Warranty',
    '30% Tax Credit Eligible',
    'Most Fair Price in Texas'
]

const priceListItems = [
    'Solar panels approx cost in Texas: ¢ 20-30/watt',
    'Racking cost in Texas: ¢ 12-15/watt',
    'Inverter cost: ¢ 20-30/watt',
    'Other materials approx: ¢ 20/watt',
    'Skilled labor: ¢ 30/watt',
    'Engineering/Permitting: ¢ 10/watt',
    'Overhead and profit: ¢ 50/watt',
    'Warranty: ¢ 10/watt',
]

export const PricingDetails = () => {

    return (
        <section className="pricing-details-section">
            <Typography color={'#1C364F'} textAlign={'center'} mb={4} variant="h4">Transparent Pricing</Typography>
            <Container>
                <Card className="pricing-block">
                    <CardContent className="pricing-block-content">
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={6} lg={4} alignSelf={'center'}>
                                <List className="main-list">
                                    {listItems.map((item, index) => {
                                        return (
                                            <ListItem key={index}>
                                                <ListItemIcon className="list-icons">{checkIcon}</ListItemIcon>
                                                <ListItemText primary={item} />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </Grid>
                            <Grid item xs={12} sm={12} md={6} lg={3} alignSelf={'center'}>
                                <div className="price-block">
                                    <Typography variant="h4">$1.90<span>/ Watts</span></Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} lg={5}>
                                <List className="main-list">
                                    {priceListItems.map((item, index) => {
                                        return (
                                            <ListItem key={index}>
                                                <ListItemIcon className="list-icons">{arrowIcon}</ListItemIcon>
                                                <ListItemText primary={item} />
                                            </ListItem>
                                        )
                                    })}
                                </List>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            </Container>
        </section>
    )
}
