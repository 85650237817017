import * as React from "react"
import { Box, Card, CardContent, Container, Grid, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material"
import AddBattery from "../home/addBattery"
import ContactUs from "../../components/contact-us"
import { AcUnit, HotTub, Hvac, Iron, Kitchen, Lightbulb, Power, Tv, WindPower } from "@mui/icons-material"

const averageLoadSpecs = [
  { name: 'Central AC', power: '3500-6000 watts/hour', icon: <AcUnit /> },
  { name: 'Pool Pump', power: '1500-2500 watts/hour', icon: <Iron /> },
  { name: 'Hot Tub', power: '1500-6000 watts/hour', icon: <HotTub /> },
  { name: 'Hot Water Heater', power: '1125-4000 watts/hour', icon: <Hvac /> },
  { name: 'Electric Heating', power: '1500-5000 watts/hour', icon: <Power /> },
  { name: 'Fridge and Freezer', power: '300-800 watts/hour', icon: <Kitchen /> },
  { name: 'A TV', power: '50-200 watts/hour', icon: <Tv /> },
  { name: 'A Fan', power: '50-75 watts/hour', icon: <WindPower /> },
  { name: 'Light', power: '25-100 watts/hour', icon: <Lightbulb /> }
];

export const BatterySpecs = () => {
  const partSize = Math.ceil(averageLoadSpecs.length / 3);
  const firstPart = averageLoadSpecs.slice(0, partSize);
  const secondPart = averageLoadSpecs.slice(partSize, partSize * 2);
  const thirdPart = averageLoadSpecs.slice(partSize * 2);

  return (
    <div className="wrapper">
      <div className="main-content battery-main-content">
        <section className="main-section">
          <Container maxWidth="xl">

            {/* Battery Education */}
            <div className="cs-content">
              <Typography variant="h1">Battery Education</Typography>
              <div className="battery-content">
                <p>
                  Without a battery system solar that is interconnected with
                  the electrical grid can't keep producing power in an outage.
                  This is a very common misconception. Batteries are required
                  for power in an outage.
                </p>
                <p>
                  Depending on the utility company and how they treat the
                  exported energy into the grid a battery can provide a
                  financial benefit keeping the power instead of sending it to
                  the utility company. This is often referenced as creating
                  your own micro grid.
                </p>
                <p>
                  Typically the two different streams of thought are backing
                  up essential loads power or trying to back up the entire
                  home. With an essential loads system the installer will do a
                  sub panel off the main electrical panel. In an outage the
                  automatic transfer switch moves the power from the main to
                  the sub panel. The sub panel will typically only contain 120
                  V power loads. Common loads would be fridge, freezer,
                  lighting, and wifi. Commonly not doing any 240 V loads.
                  Specifically leaving off of Ac units and pool pumps.
                </p>
                <p>
                  Whole home battery back up still requires being conservative
                  with your power in an outage most likely (if cost is an
                  issue). Load controllers are often very important to achieve
                  whole home battery back up. 240 V loads are able to be
                  backed up. The systems will work very quickly in an outage.
                  Often times in milliseconds they switch power from grid to
                  all solar and batteries. If the sun is reaching the panels
                  most systems will still allow the solar panels to work in an
                  outage. A lot of utility companies, federal, state, and
                  local governments are offering incentives to do batteries
                  right now to help the electrical grids be more safe.
                </p>
              </div>
            </div>

            {/* Add Battery */}
            <AddBattery />

            {/* Battery Specs */}
            <Box className="battery-specs-wrapper">
              <Typography variant="h4" className="title">Battery Specs</Typography>
              <Grid container spacing={3}>
                {/* Power Card */}
                <Grid item xs={12} md={4}>
                  <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
                    <CardContent className="specs-card">
                      <Typography variant="h5" className="card-title">Power</Typography>
                      <Typography className="inner-details">11500 <span>Watts</span></Typography>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Storage Card */}
                <Grid item xs={12} md={4}>
                  <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
                    <CardContent className="specs-card">
                      <Typography variant="h5" className="card-title">Storage</Typography>
                      <Typography className="inner-details">13.5 <span>kWh</span></Typography>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Dimensions Card */}
                <Grid item xs={12} md={4}>
                  <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
                    <CardContent className="specs-card">
                      <Typography variant="h5" className="card-title">Dimensions</Typography>
                      <Typography className="inner-details">43.5 x 24 x 7.6 <span className="new-line">(in inches)</span> <span className="new-line">(Height x Width x Depth)</span></Typography>
                    </CardContent>
                  </Card>
                </Grid>

                {/* Average Load Specs List */}
                <Grid item xs={12}>
                  <Card sx={{ boxShadow: 3, borderRadius: 3 }}>
                    <CardContent className="specs-card">
                      <Typography variant="h5" className="card-title" pb={2}>Average Load Specs</Typography>
                      <List className="average-load-list" sx={{ width: '100%', bgcolor: 'background.paper' }}>
                        <Grid container spacing={0}>
                          <Grid item xs={12} md={4}>
                            {firstPart.map((item, index) => (
                              <ListItem key={index}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText
                                  primary={<Typography className="load-power">{item.power}</Typography>}
                                  secondary={<Typography className="load-name">{item.name}</Typography>}
                                />
                              </ListItem>
                            ))}
                          </Grid>
                          <Grid item xs={12} md={4}>
                            {secondPart.map((item, index) => (
                              <ListItem key={index}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText
                                  primary={<Typography className="load-power">{item.power}</Typography>}
                                  secondary={<Typography className="load-name">{item.name}</Typography>}
                                />
                              </ListItem>
                            ))}
                          </Grid>
                          <Grid item xs={12} md={4}>
                            {thirdPart.map((item, index) => (
                              <ListItem key={index}>
                                <ListItemIcon>{item.icon}</ListItemIcon>
                                <ListItemText
                                  primary={<Typography className="load-power">{item.power}</Typography>}
                                  secondary={<Typography className="load-name">{item.name}</Typography>}
                                />
                              </ListItem>
                            ))}
                          </Grid>
                        </Grid>
                      </List>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Box>

            {/* Contact Us */}
            <ContactUs />

          </Container>
        </section>
      </div>
    </div>
  )
}
