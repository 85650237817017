import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

// UI Routing List
import Home from "./pages/home";
import About from "./pages/about-us";
// import BatteryCalculator from "./pages/battery-calculate";
import { BatterySpecs } from "./pages/battery-specs";
import Financing from "./pages/financing";
import ContactUs from "./pages/contact-us";
import Main from "./pages/main";
import Faq from "./pages/faq";
import ScrollToTop from "./components/scrollToTop.js";

export const RouteList = () => {

  const fetchIntialData = React.useCallback(async () => {
    if (window && window.document) {
      const bodyQuerySelector = window.document.querySelector("body");
      const scriptQuerySelector = window.document.querySelector("#google-map-api");
      if (bodyQuerySelector !== null && scriptQuerySelector === null) {
        const scriptElement = window.document.createElement("script");
        scriptElement.setAttribute("id", "google-map-api");
        scriptElement.setAttribute("type", "text/javascript");
        scriptElement.setAttribute("charset", "UTF-8");
        scriptElement.setAttribute("async", "true");
        scriptElement.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API_KEY}&libraries=places,drawing,geometry&v=weekly&region=US&language=en&callback=Function.prototype`;
        bodyQuerySelector.appendChild(scriptElement);
      }
    }
  }, [])

  React.useEffect(() => {
    fetchIntialData()
  }, [fetchIntialData])

  return (
    <BrowserRouter>
      <ScrollToTop /> 
      <Routes>
        <Route path="/" element={<Main />}>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<About />} />
          {/* <Route path="/materials" element={<Materials />} /> */}
          {/* <Route path="/battery-calculate" element={<BatteryCalculator />} /> */}
          <Route path="/battery-specs" element={<BatterySpecs />} />
          <Route path="/financing" element={<Financing />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/faq" element={<Faq />} />
        </Route>
      </Routes>
    </BrowserRouter >
  );
};
