import { createTheme } from "@mui/material/styles";

let customTheme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 576,
      md: 768,
      lg: 992,
      xl: 1200,
      xxl: 1400,
    },
  },
  typography: {
    fontFamily: "'Poppins', sans-serif",
  },
  palette: {
    primary: {
      main: "#0170D6",
      contrastText: "#fff",
    },
    secondary: {
      main: "#EDF2FC",
      contrastText: "#626A74",
    },
    error: {
      main: "#da402f",
      contrastText: "#fff",
    },
    text: {
      primary: "#3F444C",
    },
  },
});

export const Theme = createTheme(customTheme, {
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          fontSize: "1.4rem",
          fontWeight: 400,
          lineHeight: "2rem",
          fontFamily: "'Poppins', sans-serif",
          backgroundColor: "#FAFBFD",
          color: "#565656",
          letterSpacing: 0,

          [customTheme.breakpoints.up("lg")]: {
            fontSize: "1.6rem",
            lineHeight: "2.2rem",
          },
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "transparent",
          boxShadow: "none",
          left: 0,
          right: 0,
          top: 0,
          zIndex: 9,
          flexDirection: "row",
          padding: "2.4rem 1.6rem",
          transition: "all 0.5s",
          [customTheme.breakpoints.up("md")]: {
            padding: "2.9rem 0",
          }
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          [customTheme.breakpoints.up("xl")]: {
            maxWidth: "124.8rem",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          fontSize: "1.6rem",
          lineHeight: "2.2rem",
          borderRadius: "0.6rem",
          backgroundColor: "#FFFFFF",
          paddingRight: "1.2rem",
          fontWeight: 400,
          color: '#3F444C',
          "&.Mui-focused": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#0170D6",
            },
          },
          "&:hover": {
            ".MuiOutlinedInput-notchedOutline": {
              borderColor: "#0170D6",
            },
          },
        },
        input: {
          padding: "1.2rem 1.3rem",
        },
        notchedOutline: {
          borderColor: "#CED4DA",
          "&:hover:not(&.MuiOutlinedInput-notchedOutline)": {
            backgroundColor: "#0170D6",
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        outlined: {
          transform: "translate(1rem, 1.3rem) scale(1)",
          fontSize: "1.4rem",
          fontWeight: 400,
          color: "#81838A",
          // padding: "4px 8px",
          "&.MuiInputLabel-shrink": {
            transform: "translate(1.6rem, -0.6rem) scale(0.75)",
            color: "#565656",

          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        gutters: {
          fontSize: "1.4rem",
          transition: "all 0.5s",
          "&:hover": {
            backgroundColor: "rgba(1, 112, 214, 0.1)",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          boxShadow: "none",
          borderRadius: "0.6rem",
          textTransform: "capitalize",
          letterSpacing: "0.01em",
          fontSize: "1.6rem",
          fontWeight: 700,
          padding: "0 4rem",
          minWidth: "100%",
          minHeight: "5.2rem",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "#00519c",
          },
          [customTheme.breakpoints.up("md")]: {
            minWidth: "16.2rem",
          }
        },
        containedSecondary: {
          boxShadow: "none",
          borderRadius: "0.6rem",
          textTransform: "capitalize",
          letterSpacing: "0.01em",
          fontSize: "1.6rem",
          fontWeight: 600,
          padding: "0 2.5rem",
          minHeight: "5.2rem",
          color: "#626a74",
          minWidth: "100%",
          "&:hover": {
            boxShadow: "none",
            backgroundColor: "#ccd3e1",
          },
          [customTheme.breakpoints.up("md")]: {
            minWidth: "9.5rem",
          }
        },
        outlinedPrimary: {
          border: "0.1rem solid #0170D6",
          backgroundColor: "transparent",
          fontSize: "1.6rem",
          fontWeight: 600,
          color: "#0170D6",
          textTransform: "capitalize",
          padding: "0 2.5rem",
          minHeight: "5.2rem",
          lineHeight: "1.8rem",
          borderRadius: "0.6rem",
          letterSpacing: "0.01em",
          minWidth: "100%",
          [customTheme.breakpoints.up("md")]: {
            minWidth: "13.1rem",
          }
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          borderRadius: 0,
          height: "100%",

          [customTheme.breakpoints.up("md")]: {
            borderRadius: "2.4rem",
            boxShadow: "0 2px 15px rgba(10, 41, 69, 0.2)",
          }
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: "1.6rem",
          "&:last-child": {
            paddingBottom: "1.6rem",
          },
          [customTheme.breakpoints.up("md")]: {
            padding: "2.4rem",
            "&:last-child": {
              paddingBottom: "2.4rem",
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        multiline: {
          minHeight: "10.8rem",
          alignItems: "flex-start",
          padding: "0 !important",
          textarea: {
            padding: 0,
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#fff",
          borderRadius: "0.6rem",
          boxShadow: "0 2px 15px rgba(0, 0, 0, 0.2)",
          marginBottom: 0,
          maxWidth: "14.5rem",
          padding: "0.8rem",
          fontSize: "1.2rem",
          lineHeight: "1.8rem",
          fontWeight: 400,
          color: "#565656",
        },
        arrow: {
          "&:before": {
            backgroundColor: "#fff",
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "&.custom-date-button": {
            ".MuiIconButton-root": {
              margin: 0,
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "0.6rem",
          margin: "0 1.5rem",
          maxWidth: "calc(100% - 3rem)",
          boxShadow: "0 0.3rem 1rem rgba(0, 0, 0, 0.2)",
          width: "45.3rem",
          padding: "3.8rem 1.5rem",
          [customTheme.breakpoints.up("sm")]: {
            padding: "3.8rem 2rem",
          },
        },
      },
    },
  },
  typography: {
    h1: {
      fontWeight: 600,
      color: "#1C364F",
      fontSize: "3.2rem",
      lineHeight: "4rem",
      textTransform: "capitalize",
      [customTheme.breakpoints.up("md")]: {
        fontSize: "5rem",
        lineHeight: "6.2rem",
      },
      [customTheme.breakpoints.up("lg")]: {
        fontSize: "6rem",
        lineHeight: "7.2rem",
      },
    },
    h2: {
      fontSize: "3.2rem",
      lineHeight: "3.8rem",
      fontWeight: 600,
      color: "#fff",
      [customTheme.breakpoints.up("md")]: {
        fontSize: "3.4rem",
        lineHeight: "4rem",
      },
      [customTheme.breakpoints.up("lg")]: {
        fontSize: "3.8rem",
        lineHeight: "5.7rem",
      },
      "&.h2": {
        fontSize: "3.2rem",
        lineHeight: "3.8rem",
        fontWeight: 600,
        color: "#193766",
      },
    },

    h3: {
      fontSize: "2.6rem",
      lineHeight: "3.9rem",
      fontWeight: 500,
      color: "#fff",
      "&.h3": {
        fontSize: "3.2rem",
        lineHeight: "4.8rem",
        fontWeight: 600,
        color: "#fff",
        [customTheme.breakpoints.up("md")]: {
          fontSize: "4.2rem",
          lineHeight: "4.4rem",
        },
      },
    },

    h4: {
      fontSize: "3.2rem",
      lineHeight: "3.8rem",
      fontWeight: 600,
      color: "#193766",
      [customTheme.breakpoints.up("md")]: {
        fontSize: "3.5rem",
        lineHeight: "3.7rem",
      },
      [customTheme.breakpoints.up("lg")]: {
        fontSize: "4.2rem",
        lineHeight: "4.4rem",
      },
      "&.h4": {
        fontSize: "2rem",
        lineHeight: "2.6rem",
        fontWeight: 600,
        color: '#193766',
        [customTheme.breakpoints.up("sm")]: {
          fontSize: "2.8rem",
          lineHeight: "4.4rem",
        },
      },
    },

    h5: {
      fontSize: "1.8rem",
      lineHeight: "2.2rem",
      fontWeight: 500,
      color: "#373737",
      "&.h5": {
        fontSize: "1.6rem",
        lineHeight: "2rem",
        fontWeight: 600,
        color: '#3F444C',
      },
    },

    h6: {
      fontSize: "1.4rem",
      lineHeight: "2rem",
      fontWeight: 500,
      color: "#3F444C",
    },
  },
});
